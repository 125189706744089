import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import i18next from 'i18next';
import {logo} from '../../assets';
import {getItem} from '../../helper/persistance-storege';
import {useSelector} from "react-redux";

const Navbar = () => {
    const lang = getItem('lang')
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {navbarHeight} = useSelector(state => state.generalVariable)
    const {favouriteIds} = useSelector(state => state.categorySlice)
    const navigate = useNavigate()

    const changeLanguage = (e) => {
        localStorage.setItem('lang', e.target.value);
        window.location.reload();
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav
            className="navbar navbar-expand-lg fixed-top navbar-light bg-light"
            style={{height: navbarHeight + 'px'}}
        >
            <div className="container-fluid">
                <div className="left">
                    <Link
                        to="/"
                        className="home-link nuxt-link-exact-active nuxt-link-active justify-content-center"
                        aria-current="page"
                    >
                        <span className={"logo-picture"}>
                            <img
                                src={logo}
                                alt="logo"
                                width={45}
                                height={45}
                                style={{borderRadius: '50%'}}
                            />
                        </span>
                        <h2 className="logo-text fw-bold">
                            {i18next.t('company_name')}
                        </h2>
                    </Link>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isMenuOpen ? 'true' : 'false'}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className={`collapse navbar-collapse justify-content-center ${
                        isMenuOpen ? 'show' : ''
                    }`}
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav justify-content-center nav_ul align-items-center dark">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">
                                {i18next.t('home')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">
                                {i18next.t('about_company')}
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                {i18next.t('products')}
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <Link className="dropdown-item" to="category">
                                        {i18next.t('products')}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="https://test.itm-llc.uz">
                                        {i18next.t('catalog')}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/news-list">
                                {i18next.t('news')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">
                                {i18next.t('contact')}
                            </Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav nav_ul mt-lg-0 d-flex align-items-center menu-right">
                        <li>
                            <select onChange={changeLanguage} value={lang}>
                                <option value="uz">Uzb</option>
                                <option value="en">Eng</option>
                                <option value="ru">Rus</option>
                            </select>
                        </li>
                        <li onClick={() => {
                            navigate("/product-favourite")
                        }}>
                            <span className="item-floating heart">{favouriteIds ? favouriteIds.length : 0}</span>
                            <i className="ri-heart-line"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
