import React, {useEffect, useState} from 'react';

import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import {Navigation, Pagination, Thumbs} from "swiper/modules";
import {unknownImage} from "../../assets";
import {useDispatch, useSelector} from "react-redux";
import {failure, openAnswerModal, openReviewModal, openShareModal} from "../../slice/generalVariable";
import {Actions, QuestionPopup, ReviewPopup, SharePopup} from "../index";
import {getProductFailure, getProductStart, getProductSuccess} from "../../slice/productSlice";
import ProductService from "../../service/productService";
import {useNavigate, useParams} from "react-router-dom";
import {Img, Loading} from "../../ui";
import i18next from "i18next";
import {getDate} from "../../helper/data";
import {toggleFavourite} from "../../slice/categorySlice";

const Product = () => {
    const dispatch = useDispatch()
    const {productId} = useParams()
    const navigate = useNavigate()
    const {isLoading, product, imageTotalCount} = useSelector(state => state.productSlice)
    const {favouriteIds} = useSelector(state => state.categorySlice)
    const [activeThumb, setActiveThumb] = useState(null)
    const {loggedIn} = useSelector(state => state.authSlice)
    const [imageLoadedCount, setImageLoadedCount] = useState(0)
    const [productCount, setProductCount] = useState(0)

    useEffect(() => {
        const getProduct = async () => {
            dispatch(getProductStart())
            try {
                const response = await ProductService.get(productId);
                if (response.success) {
                    dispatch(getProductSuccess(response.data))
                } else {
                    dispatch(getProductFailure())
                    dispatch(failure(response.appErrorDto))
                }
            } catch (error) {
                console.log(error);
                dispatch(getProductFailure())
            }
        }
        getProduct().catch(error => console.log(error))
    }, [])

    const getStar = (rating) => {
        const elements = []

        for (let i = 0; i < rating; i++) {
            elements.push(
                <React.Fragment key={i}>
                    <i className="ri-star-fill" style={{color: '#ffa502'}}></i>
                </React.Fragment>
            );
        }

        for (let i = 0; i < (5 - rating); i++) {
            elements.push(
                <React.Fragment key={5 - i}>
                    <i className="ri-star-fill"></i>
                </React.Fragment>
            );
        }

        return elements;
    }

    const updateHandler = () => {
        navigate(`/admin-page/product-update/${productId}`)
    }

    const deleteHandler = async () => {
        await ProductService.delete(productId);
        navigate("/category")
    }

    const imageLoadHandler = () => {
        setImageLoadedCount(count => (count + 1))
    }

    const decreaseHandler = () => {
        setProductCount(count => {
            if (count > 0) {
                return (count - 1);
            }
            return 0;
        })
    }

    const increaseHandler = () => {
        setProductCount(count => (count + 1))
    }

    const toggle = (e) => {
        e.preventDefault()
        dispatch(toggleFavourite(productId))
    }

    return (<>
            {(isLoading || 2 * imageTotalCount !== imageLoadedCount) && <Loading/>}
            {(product) &&
                <div className="container"
                     style={{display: isLoading || 2 * imageTotalCount !== imageLoadedCount ? "none" : "block"}}
                >
                    <div className="wrap">
                        <div className="product dotgrid">
                            <div className="wrapper">
                                <div className="image">
                                    <div className="outer-main">
                                        <Swiper
                                            loop={true}
                                            spaceBetween={10}
                                            modules={[Thumbs, Pagination]}
                                            grabCursor={true}
                                            pagination={{clickable: true}}
                                            thumbs={activeThumb ? {swiper: activeThumb} : undefined}
                                            className={'main-image'}
                                        >
                                            <>
                                                {product.pictureUrls.map((pictureUrl, index) => (
                                                    <SwiperSlide key={index} className={'item'}>
                                                        <Img
                                                            pictureUrl={pictureUrl}
                                                            onLoad={imageLoadHandler}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </>
                                        </Swiper>
                                    </div>
                                    <div className="outer-thumb ob-cover">
                                        <Swiper
                                            onSwiper={setActiveThumb}
                                            watchSlidesProgress={true}
                                            loop={true}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            modules={[Navigation, Thumbs]}
                                            direction={"vertical"}
                                            className={'thumbnail-image'}
                                        >
                                            <>{product.pictureUrls.map((pictureUrl, index) => (
                                                <SwiperSlide key={index} className={'item'}>
                                                    <div className="thumb-wrap">
                                                        <Img
                                                            pictureUrl={pictureUrl}
                                                            onLoad={imageLoadHandler}
                                                        />
                                                    </div>
                                                </SwiperSlide>
                                            ))}</>
                                        </Swiper>
                                    </div>
                                </div>
                                <div className="summary">
                                    <div className="entry">
                                        <h1 className="title">{product.name}</h1>
                                        <div className="text-block">
                                            <div className="grey-color">
                                                <p>{product.description}</p>
                                            </div>
                                        </div>
                                        <div className={"product-category"}>
                                            <h4 style={{marginBottom: "0"}}>{i18next.t("category")}:</h4>
                                            <div className={"grey-color"}>
                                                {product.category}
                                            </div>
                                        </div>
                                        <div className={"vendor-code"}>
                                            <h4 style={{marginBottom: "0"}}>{i18next.t("vendorCode")}:</h4>
                                            <div className={"grey-color"}>
                                                {product.vendorCode}
                                            </div>
                                        </div>
                                        <div>
                                            <h4>{i18next.t("technical_data")}:</h4>
                                            <ul className="grey-color">
                                                {product.technicalDatas.map((data, index) => (<li key={index}>
                                                    {data}
                                                </li>))}
                                            </ul>
                                        </div>
                                        <div className="product-group">
                                            <div className="product-price">
                                            <span
                                                className="current">{product.price !== 0 ? `${product.price} ${i18next.t("som")}` : `${i18next.t("agreed")}`}</span>
                                            </div>
                                            <div className="product-rating" style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                gap: "5px"
                                            }}>
                                                <i className="ri-star-fill" style={{color: '#ffa502'}}></i>
                                                <a href={'#0'} style={{color: "#000", fontSize: '16px'}}
                                                   onClick={(e) => e.preventDefault()}>{`${product.averageRating} / 5`}</a>
                                                <a href="#0"
                                                   onClick={(e) => e.preventDefault()}>{`${product.reviews.length} ${i18next.t("reviews")}`}</a>
                                            </div>
                                        </div>
                                        <div className="product-action">
                                            <div className="qty">
                                                <button className="decrease" onClick={decreaseHandler}>-</button>
                                                <input type="text" value={productCount} readOnly={true}/>
                                                <button className="increase" onClick={increaseHandler}>+</button>
                                            </div>
                                            <div className="addcart button">
                                                <button type="submit"
                                                        className="primary-btn">{i18next.t("add_to_card")}</button>
                                            </div>
                                        </div>
                                        <div className="product-control list-inline">
                                            <ul>
                                                <li><a href="#0" onClick={toggle}>
                                                    {favouriteIds && favouriteIds.includes(parseInt(productId)) ?
                                                        <i className="ri-heart-fill" style={{color: "#FF0000"}}></i> :
                                                        <i className="ri-heart-line"></i>
                                                    }
                                                    <span>{i18next.t("add_to_wishlist")}</span>
                                                </a></li>
                                                <li><a href="#0" onClick={(e) => {
                                                    e.preventDefault()
                                                    dispatch(openShareModal())
                                                }}>
                                                    <i className="ri-share-forward-line"></i>
                                                    <span>{i18next.t("share")}</span>
                                                </a></li>
                                                <li><a href="#0" onClick={(e) => {
                                                    e.preventDefault()
                                                    dispatch(openAnswerModal())
                                                }}>
                                                    <i className="ri-question-line"></i>
                                                    <span>{i18next.t("ask_question")}</span>
                                                </a></li>
                                            </ul>
                                        </div>
                                        {loggedIn &&
                                            <Actions updateHandler={updateHandler} deleteHandler={deleteHandler}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product detail">
                            <div className="wrapper tabbed">
                                <div id="product-review" className="product-about review active">
                                    <div className="wrapper">
                                        <h3>{i18next.t("rating")} & {i18next.t("reviews")}</h3>
                                        <div className="head-review" style={{marginBottom: "20px"}}>
                                            <div className="sum-rating">
                                                <strong>{product.averageRating}/5</strong>
                                                <div>{`${product.reviews.length} ${i18next.t("reviews")}`}</div>
                                            </div>
                                            <div className="button" onClick={(e) => {
                                                e.preventDefault()
                                                dispatch(openReviewModal())
                                            }}>
                                                <a href="#0"
                                                   className="primary-btn hover">{i18next.t("write_reviews")}</a>
                                            </div>
                                        </div>
                                        <div className="body-review">
                                            {product.reviews.map((review) => (
                                                <div className="profile" key={review.id}>
                                                    <div className="thumb-name">
                                                        <div className="image">
                                                            <img src={unknownImage} alt=""/>
                                                        </div>
                                                        <div className="grouping">
                                                            <div className="name">{review.name}</div>
                                                            <div className="rating">
                                                                {getStar(review.rating)}
                                                            </div>
                                                            <div
                                                                className="date grey-color">{getDate(review.createdAt)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="comment">
                                                        <strong>{review.title}</strong>
                                                        <p className="grey-color">{review.review_content}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            {product ? <SharePopup id={product.id}/> : <SharePopup/>}
            <QuestionPopup/>
            <ReviewPopup/>
        </>
    );
};


export default Product;